import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
  }

  resize() {
    this.element.style.height = "1px"
    this.element.style.height = (this.element.scrollHeight)+"px"
  }
}
