import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['tabItem', 'tabBody']

  toggle(event) {
    event.preventDefault()

    this.tabBodyTargets.forEach((tab) => {
      tab.classList.add('hidden')
    })

    this.tabItemTargets.forEach((tab) => {
      tab.classList.remove('active')
    })

    console.log(event.target.dataset.target)
    event.target.classList.add('active')
    document.getElementById(event.target.dataset.target).classList.remove('hidden')
  }
}

