import { Controller } from "stimulus"
import { useClickOutside } from 'stimulus-use'

export default class extends Controller {
  static targets = ['sub']

  connect() {
    this.headroom = document.querySelector('#header').headroom.headroom

    this.addActiveClasses()
  }

  disconnect() {
    this.closeSub()
    this.close()
  }

  toggleSub() {
    event.preventDefault()

    let clickedNavigationId = event.currentTarget.dataset.headerNavigationId

    if (this.currentNavigationId == clickedNavigationId) {
      this.closeSub()
    } else {
      this.currentNavigationId = clickedNavigationId
      this.subTargets.forEach((el) => {
        if (el.dataset.headerNavigationId == this.currentNavigationId) {
          el.classList.remove('hidden')
        } else {
          el.classList.add('hidden')
        }
      })
    }
  }

  closeSub() {
    this.subTargets.forEach((el) => {
      el.classList.add('hidden')
    })
    this.currentNavigationId = undefined
  }

  toggle() {
    if (this.element.classList.contains('hidden')) {
      this.open()
    } else {
      this.close()
    }
  }

  open() {
    this.headroom.pin()
    this.headroom.freeze()
    this.element.classList.remove('hidden')
  }

  close() {
    this.headroom.unfreeze()
    this.element.classList.add('hidden')
  }

  addActiveClasses() {
    // let current = location.pathname
    // console.log(current)
    this.element.querySelectorAll('a').forEach((el) => {
      // if the current path is like this link, make it active
      if(el.href.endsWith(location.pathname)){
        el.classList.add('active')

        this.getParents(el, '[data-header-navigation-target="sub"]').forEach((parent) => {
          parent.previousElementSibling.querySelector('a').classList.add('active')
        })
      }
    })
  }

  getParents = function (elem, selector) {
    // Element.matches() polyfill
    if (!Element.prototype.matches) {
      Element.prototype.matches =
        Element.prototype.matchesSelector ||
        Element.prototype.mozMatchesSelector ||
        Element.prototype.msMatchesSelector ||
        Element.prototype.oMatchesSelector ||
        Element.prototype.webkitMatchesSelector ||
        function(s) {
          var matches = (this.document || this.ownerDocument).querySelectorAll(s),
            i = matches.length;
          while (--i >= 0 && matches.item(i) !== this) {}
          return i > -1;
        };
    }

    // Set up a parent array
    var parents = [];

    // Push each parent element to the array
    for ( ; elem && elem !== document; elem = elem.parentNode ) {
      if (selector) {
        if (elem.matches(selector)) {
          parents.push(elem);
        }
        continue;
      }
      parents.push(elem);
    }

    // Return our parent array
    return parents;
  };
}
