import { Controller } from "stimulus"
import SecureFields from 'src/secure-fields-2.0.0.min.js'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ['overlay', 'expirationMonth', 'expirationYear', 'error']
  static values = { config: Object }

  connect() {
    let controller = this
    this.secureFields = new SecureFields()

    Rails.ajax({
      type: "POST",
      url: '/datatrans/secure-fields',
      success: function(data) {
        if (data.transactionId) {
          controller.initializeSecureFields(data.transactionId)
        }
      }
    })

    // controller.element.closest('form').querySelector('input[type=submit]')
    this.submit_button = document.querySelector('#checkout-submit')
    this.submit_button_placeholder = document.querySelector('#checkout-submit-placeholder')
  }

  submit() {
    this.disableForm()

    this.secureFields.submit({
      expm: this.expirationMonthTarget.value,
      expy: this.expirationYearTarget.value
    })
  }

  initializeSecureFields(transactionId) {
    let controller = this
    let amount, expMonth, expYear
    let styles = {
      // or with JSON
      '*': {
        display: "block",
        width: "100%",
        borderRadius: "0.375rem",
        border: "1px solid rgba(209, 213, 219, 1)",
        verticalAlign: "baseline",
        WebkitAppearance: 'none',
        MozAppearance: 'none',
        Appearance: 'none',
        backgroundColor: "#fff",
        padding: "0.5rem 0.75rem",
        fontSize: '1rem',
        lineHeight: '1.5rem',
        fontFamily: 'Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace',
        boxShadow: "0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgba(0, 0, 0, 0.05)"
      },

      // ::hover on all elements
      // "*::hover": "-webkit-box-shadow: none; -ms-box-shadow: none; -moz-appearance: none; ",

      // set style to one of the elements
      // cardNumber: "height: auto;",
      // cvv: "height: auto;",

      // setting style based on CSS classes (see 'Toggled classes')
      // "cardNumber.valid:hover": "",
      // "cardNumber.invalid:hover": {
      //   border: '1px solid red',
      // },
      // "cvv.invalid:hover": {
      //   border: '1px solid red',
      // },

      // pseudoselectors
      // '*:focus': '',
      // '*:hover': '',
      '*::placeholder': 'color: #9ca3af',
      '*:-ms-input-placeholder': 'color: #9ca3af' // thanks MS :(
    }

    this.secureFields.init(
      transactionId,
      {
        cardNumber: "card-number-placeholder",
        cvv: "cvv-placeholder"
      }, {
        styles: styles,
        focus: "cardNumber" // or secureFields.focus("cardNumber");
      }
    )

    this.secureFields.on("ready", function() {
      controller.overlayTarget.classList.add('hidden')

      // setting a placholder for the cardNumber field
      controller.secureFields.setPlaceholder("cardNumber", "XXXX XXXX XXXX XXXX");

      // setting a placeholder for the CVV field
      controller.secureFields.setPlaceholder("cvv", "CVV");
    });

    this.secureFields.on("validate", function(event) {
      controller.errorTarget.classList.add('hidden')

      if (event.hasErrors) {
        // put a red border around the fields if they are not valid
        controller.secureFields.setStyle("cardNumber.invalid","border: 1px solid #f00");
        controller.secureFields.setStyle("cvv.invalid","border: 1px solid #f00");

        setTimeout(function () {
          controller.enableForm()
        }, 300)
      }
    });

    this.secureFields.on("success", function(data) {
      if (data.transactionId) {
        // let paymentMethodId = controller.configValue.id
        let modal
        let submit = false
        let error = false

        if (data.redirect !== undefined) {
          modal = document.querySelector('#secure-3d-modal').modal

          let iframe = controller.htmlToElement(`<iframe src='${data.redirect}'></iframe>`)
          modal.bodyTarget.appendChild(iframe)
          modal.open()
        } else {
          submit = true
        }

        // NOTE: Wait for 3d secure status
        controller.interval = setInterval(() => {
          if (submit) {
            clearTimeout(controller.interval)

            let responseCode = controller.htmlToElement(`<input type='hidden' name='order[payments_attributes][][response_code]' value='${data.transactionId}'/>`)
            controller.element.appendChild(responseCode)

            controller.element.closest('form').submit()
          } else if (error) {
            clearTimeout(controller.interval)

            Turbo.visit(window.location.toString(), { action: 'replace' })
          } else {
            Rails.ajax({
              type: "GET",
              url: `/datatrans/secure-3d/${data.transactionId}`,
              success: function(data) {
                if (data.acs_code == 'Y') {
                  submit = true
                } else if (data.acs_code != null) {
                  error = true
                }
              }
            })
          }
        }, 2000)
      }
    });

    this.secureFields.on("error", function(data) {
      // something bad happened
      // TODO Handle error
      // console.log(data)

      controller.errorTarget.classList.remove('hidden')

      setTimeout(function () {
        controller.enableForm()
      }, 300)
    });
  }

  disableForm() {
    this.submit_button.disabled = true
    // this.submit_button.classList.add('hidden')
    // this.submit_button_placeholder.classList.remove('hidden')
    this.overlayTarget.classList.remove('hidden')
  }

  enableForm() {
    Rails.enableElement(this.submit_button)
    this.submit_button.disabled = false
    // this.submit_button.classList.remove('hidden')
    // this.submit_button_placeholder.classList.add('hidden')
    this.overlayTarget.classList.add('hidden')
  }

  disconnect() {
    this.secureFields.destroy()
  }

  htmlToElement(html) {
    var template = document.createElement('template');
    html = html.trim(); // Never return a text node of whitespace as the result
    template.innerHTML = html;
    return template.content.firstChild;
  }
}
