import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ["entries", "pagination", "loadingIndicator"]
  static values = { autoLoad: Boolean }

  initialize() {
    let options = {
      rootMargin: '200px',
    }

    this.intersectionObserver = new IntersectionObserver(entries => this.processIntersectionEntries(entries), options)
  }

  connect() {
    this.intersectionObserver.observe(this.paginationTarget)
  }

  disconnect() {
    this.intersectionObserver.unobserve(this.paginationTarget)
  }

  processIntersectionEntries(entries) {
    if (this.autoLoadValue) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.loadMore(event)
        }
      })
    }
  }

  loadMore() {
    if (event != undefined) {
      event.preventDefault()
    }

    let next_page = this.paginationTarget.querySelector("a[rel='next']")
    if (next_page == null) { return }
    let url = next_page.href

    Rails.ajax({
      type: 'GET',
      url: url,
      dataType: 'json',
      beforeSend: (xhr) => {
        next_page.remove()
        this.loadingIndicatorTarget.classList.remove('hidden')
        return true
      },
      success: (data) => {
        this.entriesTarget.insertAdjacentHTML('beforeend', data.entries)
        this.paginationTarget.innerHTML = data.pagination
        this.autoLoadValue = true
      }
    })
  }
}
