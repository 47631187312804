import { Controller } from "stimulus"
import { useDispatch } from 'stimulus-use'

export default class extends Controller {
  static targets = ['input', 'add', 'sub']

  connect() {
    useDispatch(this)
  }

  add() {
    event.preventDefault()
    this.inputTarget.value = parseInt(this.inputTarget.value) + 1
    this.subTarget.disabled = false
    this.dispatchUpdate()
  }

  sub() {
    event.preventDefault()
    this.inputTarget.value = parseInt(this.inputTarget.value) - 1
    if (this.inputTarget.min >= this.inputTarget.value) {
      this.subTarget.disabled = true
    }
    this.dispatchUpdate()
  }

  change() {
    this.subTarget.disabled = (this.inputTarget.min >= this.inputTarget.value)
    if (this.inputTarget.value < this.inputTarget.min) {
      this.inputTarget.value = this.inputTarget.min
    }
    this.dispatchUpdate()
  }

  dispatchUpdate() {
    let controller = this
    this.delay(function() {
      controller.dispatch('update', { quantity: controller.inputTarget.value })
    }, 300)
  }

  delay = (function() {
    let timer = 0;
    return function(callback, ms) {
      clearTimeout(timer);
      timer = setTimeout(callback, ms);
    };
  })();
}
