// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbo from "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
// import "channels"

Rails.start()

Rails.delegate(document, Rails.linkDisableSelector,   "turbo:before-cache", Rails.enableElement)
Rails.delegate(document, Rails.buttonDisableSelector, "turbo:before-cache", Rails.enableElement)
Rails.delegate(document, Rails.buttonDisableSelector, "turbo:submit-end", Rails.enableElement)

Rails.delegate(document, Rails.formSubmitSelector, "turbo:submit-start", Rails.disableElement)
Rails.delegate(document, Rails.formSubmitSelector, "turbo:submit-end", Rails.enableElement)
Rails.delegate(document, Rails.formSubmitSelector, "turbo:before-cache", Rails.enableElement)

ActiveStorage.start()

require.context('../images', true)

import "controllers"

import "stylesheets/application"

let containerScrollTops = {};

addEventListener("turbo:submit-start", () => {
  document
    .querySelectorAll("[data-turbo-preserve-scroll-container]")
    .forEach((ele) => {
      containerScrollTops[ele.dataset.turboPreserveScrollContainer] =
        ele.scrollTop;
    });
});

addEventListener("turbo:frame-render", () => {
  document
    .querySelectorAll("[data-turbo-preserve-scroll-container]")
    .forEach((ele) => {
      const containerScrollTop =
        containerScrollTops[ele.dataset.turboPreserveScrollContainer];
      if (containerScrollTop) ele.scrollTo(0, containerScrollTop);
    });

  containerScrollTops = {};
});
