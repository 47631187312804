import { Controller } from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = []

  connect() {
  }

  update() {
    Rails.fire(this.element, 'submit')
  }
}
