import { Controller } from "stimulus"
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

export default class extends Controller {
  connect() {
    tippy(this.element, {
      allowHTML: true,
      interactive: true,
      duration: 0
    })
    // document.addEventListener("turbolinks:load", () => {
    //   tippy(document.querySelectorAll('[data-tippy-content]'), {
    //     touch: false
    //   })
    // })
  }
}
