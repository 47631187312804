import { Controller } from "stimulus"
import { useClickOutside } from 'stimulus-use'

export default class extends Controller {
  static targets = ['dialog', 'quantity']
  static values = { loaded: Boolean }

  connect() {
    useClickOutside(this)
    this.turbo_frame = this.element.querySelector('turbo-frame')
    this.headroom = document.querySelector('#header').headroom.headroom
  }

  disconnect() {
    this.close()
    this.turbo_frame.src = undefined
    this.turbo_frame.innerHTML = ''
  }

  clickOutside(event) {
    var matches = function(el, selector) {
      return (el.matches || el.matchesSelector || el.msMatchesSelector || el.mozMatchesSelector || el.webkitMatchesSelector || el.oMatchesSelector).call(el, selector)
    }

    if (!matches(event.target, ".add-to-cart-button, .add-to-cart-button *")) {
      this.close()
    }
    if (!matches(event.target, "[data-controller='header-burger'], [data-controller='header-burger'] *, [data-controller='header-navigation'] *")) {
      this.headroom.unfreeze()
    }
  }

  toggle() {
    event.preventDefault()
    if (this.dialogTarget.classList.contains('opacity-0')) {
      this.open()
    } else {
      this.headroom.unfreeze()
      this.close()
    }
  }

  open() {
    this.headroom.pin()
    this.headroom.freeze()
    if (this.loadedValue == false) {
      this.loadedValue = true
      this.turbo_frame.src = '/cart'
    }
    this.dialogTarget.classList.remove('opacity-0')
    this.dialogTarget.classList.remove('pointer-events-none')
  }

  close() {
    this.dialogTarget.classList.add('opacity-0')
    this.dialogTarget.classList.add('pointer-events-none')
  }

  update(e) {
    this.quantityTarget.textContent = e.detail.quantity
    if (parseInt(e.detail.quantity) > 0) {
      this.quantityTarget.classList.add('is-active')
    } else {
      this.quantityTarget.classList.remove('is-active')
    }
    this.loadedValue = true
    this.open()

    // NOTE: HACK
    // Reset form and remove notification
    // let form = document.querySelector('#product_form')
    // if (form) {
    //   form.reset()
    // }
    let notification = document.querySelector("[data-controller='notification']")
    if (notification) {
      notification['notification'].remove()
    }
  }
}
