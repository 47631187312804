import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['month', 'year']

  connect() {
    this.monthTarget.addEventListener('input', this.handleMonth)
    this.monthTarget.addEventListener('keypress', this.checkInputPattern)

    this.yearTarget.addEventListener('keydown', this.handleYear)
    this.yearTarget.addEventListener('keypress', this.checkInputPattern)
  }

  disconnect() {
    this.monthTarget.removeEventListener('input', this.handleMonth)
    this.monthTarget.removeEventListener('keypress', this.checkInputPattern)

    this.yearTarget.removeEventListener('keydown', this.handleYear)
    this.yearTarget.removeEventListener('keypress', this.checkInputPattern)
  }

  handleMonth = (event) => {
    var value = this.monthTarget.value.toString();
    // adds 0 to month user input like 9 -> 09
    if (value.length === 1 && value > 1) {
      this.monthTarget.value = "0" + value;
    }
    // bounds
    if (value === "00") {
      this.monthTarget.value = "01";
    } else if (value > 12) {
      this.monthTarget.value = "12";
    }
    // if we have a filled input we jump to the year input
    2 <= this.monthTarget.value.length && this.yearTarget.focus()
    event.stopImmediatePropagation();
  }

  handleYear = (event) => {
    // if the year is empty jump to the month input
    if (event.key === "Backspace" && this.yearTarget.selectionStart === 0) {
      this.monthTarget.focus()
      event.stopImmediatePropagation();
    }
  }

  checkInputPattern = (event) => {
    if (!this.inputMatchesPattern(event)) {
      return event.preventDefault();
    }
  }

  inputMatchesPattern = (event) => {
    const {
      value,
      selectionStart,
      selectionEnd,
      pattern
    } = event.target;

    const character = String.fromCharCode(event.which);
    const proposedEntry = value.slice(0, selectionStart) + character + value.slice(selectionEnd);
    const match = proposedEntry.match(pattern);

    return event.metaKey || // cmd/ctrl
      event.which <= 0 || // arrow keys
      event.which == 8 || // delete key
      match && match["0"] === match.input; // pattern regex isMatch - workaround for passing [0-9]* into RegExp
  };

}
