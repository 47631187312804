import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "row" ]
  static values = { scope: String, check: Boolean }

  connect() {
    this.toggleInputs()
  }

  toggleGroup() {
    let selected_row = this.getRow()

    // Hide all rowTargets
    this.rowTargets.forEach((el) => {
      el.classList.add('hidden')
    })

    // Show selected row
    if (selected_row) {
      selected_row.classList.remove('hidden')
    }

    // Enable / disable input fields
    this.toggleInputs()
  }

  toggleSingle() {
    let selected_row = this.getRow()

    if (selected_row.classList.contains('hidden')) {
      selected_row.classList.remove('hidden')
    } else {
      selected_row.classList.add('hidden')
    }

    this.toggleInputs()
  }

  check() {
    let selected_row = this.getRow()
    // selected_row.querySelector('input[type=radio]').checked = true
    selected_row.querySelector('input[type=radio]').click()
  }

  getRow() {
    var button = event.target
    var row_id = button.value
    this.scopeValue ||= button.name

    return this.element.querySelector(`[data-target='content-box.row'][data-id='${this.scopeValue}.${row_id}']`)
  }

  toggleInputs() {
    this.rowTargets.forEach((row) => {
      let inputs = row.querySelectorAll('input, select, textarea')
      inputs.forEach((input) => {
        if (row.classList.contains('hidden')) {
          input.disabled = true
        } else {
          input.disabled = false
        }
      })
    })
  }
}
