import { Controller } from "stimulus"
import Swiper, { Navigation, Pagination } from 'swiper'
import "swiper/swiper.min.css"
import "swiper/components/navigation/navigation.min.css"
Swiper.use([Navigation])

export default class extends Controller {
  static targets = ['']

  connect() {
    this.swiper = new Swiper(this.element, {
      // Default parameters

      // Required to disable accidental swipes when adding to cart
      shortSwipes: false,
      preventClicks: false,

      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      loop: false,

      slidesPerView: 1,
      spaceBetween: 10,
      // Responsive breakpoints
      breakpoints: {
        // when window width is >= 768px
        768: {
          slidesPerView: 2,
          spaceBetween: 20
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 30
        }
      }
    })
  }
}
