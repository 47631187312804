import { Controller } from "stimulus"
import { useDispatch } from 'stimulus-use'

export default class extends Controller {
  static targets = ['submit']

  connect() {
    let controller = this
    useDispatch(controller)

    controller.element.addEventListener('submit', function(event) {
      let formData = new FormData(this)
      let payment_method = formData.get('payment_method')
      // console.log(payment_method)
      if (payment_method == 'credit_card') {
        event.preventDefault()
        controller.dispatch('submit')
        return false
      }
    })
  }
}
