import { Controller } from "stimulus"
import Headroom from "headroom.js"

export default class extends Controller {
  static targets = ["navbar"]

  connect() {
    this.element[this.identifier] = this
    this.headroom = new Headroom(this.navbarTarget, {
      offset: 143,
      tolerance: 0
    })
    this.headroom.init()
  }
}
