import { Controller } from "stimulus"
import Swiper, { Navigation, Pagination } from 'swiper'
// import 'swiper/swiper-bundle.min.css'
import "swiper/swiper.min.css"
import "swiper/components/navigation/navigation.min.css"
import "swiper/components/pagination/pagination.min.css"
Swiper.use([Navigation, Pagination])

export default class extends Controller {
  static targets = ['']

  connect() {
    this.swiper = new Swiper(this.element, {
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }
    })
  }
}
