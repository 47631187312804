import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['shipping']

  connect() {
    this.toggleInputFields(this.shippingTarget.classList.contains('hidden'))
  }

  toggle() {
    this.shippingTarget.classList.toggle('hidden')
    this.toggleInputFields(this.shippingTarget.classList.contains('hidden'))
  }

  toggleInputFields(disabled) {
    let inputs = this.shippingTarget.querySelectorAll('input, select, textarea')
    inputs.forEach((input) => {
      input.disabled = disabled
    })
  }
}
