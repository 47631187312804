import { Controller } from "stimulus"
import { useClickOutside } from 'stimulus-use'

import Popper from 'popper.js'

export default class extends Controller {
  static targets = [ "button", "popover" ]
  static values = { placement: String }

  connect() {
    useClickOutside(this)
  }

  clickOutside(event) {
    var matches = function(el, selector) {
      return (el.matches || el.matchesSelector || el.msMatchesSelector || el.mozMatchesSelector || el.webkitMatchesSelector || el.oMatchesSelector).call(el, selector)
    }

    if (!matches(event.target, "[data-target='popper.button'], [data-target='popper.button'] *, [data-target~='popper.popover'], [data-target~='popper.popover'] *")) {
      this.close()
    }
  }

  toggle() {
    event.preventDefault()

    var reference = this.buttonTarget
    var popover = this.popoverTarget

    if (popover.classList.contains('hidden')) {
      // Show new popover
      var popper = new Popper(reference, popover, {
        placement: this.placementValue || 'bottom'
      })
      popover.classList.remove('hidden')
      popper.scheduleUpdate()
    } else {
      // Hide current popover
      // and do nothing else
      this.close()
    }

  }

  close(event) {
    if (this.hasPopoverTarget) {
      this.popoverTarget.classList.add('hidden')
    }
  }
}
