import { Controller } from "stimulus"

export default class extends Controller {
  static targets = []

  connect() {
  }

  add() {
    event.preventDefault()

    let time   = new Date().getTime()
    let regexp = new RegExp(event.currentTarget.dataset.id, 'g')
    let group  = event.currentTarget.dataset.nestedFieldsGroup
    let fields = event.currentTarget.dataset.fields.replace(regexp, time)

    if (group) {
      this.element.querySelector(`[data-nested-fields-group-id='${group}']`).insertAdjacentHTML('beforeend', fields);
    } else {
      event.currentTarget.parentNode.insertBefore(fields, event.currentTarget)
    }
  }

  remove() {
    event.preventDefault()

    let hidden_field = event.currentTarget.previousElementSibling
    let field = event.currentTarget.closest('.nested-field')

    let required_fields = field.querySelectorAll('[required]')
    required_fields.forEach((el) => {
      el.removeAttribute('required')
    })
    let custom_validation_fields = field.querySelectorAll('[data-validator]')
    custom_validation_fields.forEach((el) => {
      el.removeAttribute('data-validator')
    })

    if (hidden_field == undefined) {
      field.remove()
    } else {
      hidden_field.value = 1
      field.style.display = 'none'
    }
  }
}
