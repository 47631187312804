import { Controller } from "stimulus"
import { useDispatch } from 'stimulus-use'

export default class extends Controller {
  static targets = []

  connect() {
    useDispatch(this)
  }

  disconnect() {
  }

  toggleHeaderNavigation() {
    this.dispatch('toggle')
  }
}
