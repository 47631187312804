import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "text" ]

  connect() {
  }

  toggle() {
    this.textTargets.forEach((text) => {
      text.classList.toggle('hidden')
    })

    var sidebar = document.querySelector('.checkout-sidebar')
    sidebar.classList.toggle('hidden')
  }
}
