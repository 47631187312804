import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['price', 'originalPrice', 'url', 'imageUrl', 'deliverability', 'outOfStockButton', 'addToCartButton']

  connect() {
  }

  update(e) {
    this.priceTarget.innerHTML = e.detail.price
    if (e.detail.originalPrice == undefined) {
      this.priceTarget.classList.add('text-primary')
      this.priceTarget.classList.remove('text-red-500')
      this.originalPriceTarget.innerHTML = ''
    } else {
      this.priceTarget.classList.remove('text-primary')
      this.priceTarget.classList.add('text-red-500')
      this.originalPriceTarget.innerHTML = e.detail.originalPrice
    }
    this.urlTargets.forEach((el) => {
      el.href = e.detail.url
    })
    this.imageUrlTarget.src = e.detail.imageUrl
    this.deliverabilityTarget.innerHTML = e.detail.deliverability
    if (e.detail.stockLevel == 'out_of_stock') {
      this.outOfStockButtonTarget.classList.remove('hidden')
      this.addToCartButtonTarget.classList.add('hidden')
    } else {
      this.outOfStockButtonTarget.classList.add('hidden')
      this.addToCartButtonTarget.classList.remove('hidden')
    }
  }
}
