import { Controller } from "stimulus"
import { useDispatch } from 'stimulus-use'

export default class extends Controller {
  static targets = ['overlay']

  connect() {
    useDispatch(this)
    this.dispatch('update', { quantity: this.data.get('quantity') })
  }

  prepare() {
    this.overlayTarget.classList.remove('hidden')
  }
}
