import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["modal", "body"]

  connect() {
    this.element[this.identifier] = this
  }

  disconnect() {
  }

  open() {
    this.modalTarget.classList.remove('hidden')
  }

  close() {
    this.modalTarget.classList.add('hidden')
  }
}
