import { Controller } from "stimulus"
import { useDispatch } from 'stimulus-use'

export default class extends Controller {
  static targets = ['']

  connect() {
    useDispatch(this)
  }

  load() {
    let selectedItem = event.target.options[event.target.selectedIndex]
    Turbo.visit(selectedItem.dataset.url)
  }

  update() {
    let selectedItem = event.target.options[event.target.selectedIndex]
    this.dispatch('update', {
      price: selectedItem.dataset.price,
      originalPrice: selectedItem.dataset.originalPrice,
      url: selectedItem.dataset.url,
      imageUrl: selectedItem.dataset.imageUrl,
      deliverability: selectedItem.dataset.deliverability,
      stockLevel: selectedItem.dataset.stockLevel
    })
  }
}
